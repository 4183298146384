.ReactModal__Overlay {
	overflow: auto !important;
	padding-top: 200px !important;
}

#MUI-description {
	margin-top: 20px;
	min-height: 100px;
	font-size: 1.2rem;
	text-align: left;
}

.icon-button-text {
	font-size: 1.2rem;
	color: white;
	/* hide a tag styling */
	text-decoration: none;
	text-align: center;
}

#MUI-Card {
	width: 100%;
	min-width: 300px;
	min-height: 520px;
	overflow: auto;
	margin-top: 20px !important;
	/* padding-bottom: 20px !important; */
	box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
	background-color: var(--black);

	/* border-radius: 20px; */
}

#MUI-Card:hover {
	/* darken the card on hover */
	background-color: var(--black 0.1);
}

#card-media {
	width: 100%;
	height: 250px;
	/* border-radius: "10px"; */
	/* pointer to show that it is clickable */
	/* cursor: pointer; */
	cursor: url("../../../images/ring-pointer.png"), 4, 4 pointer !important;
}

/* mobile only */
@media (max-width: 1300px) {
	.project-flag-div {
		display: none !important;
	}
	.icon-button-text {
		font-size: 1rem;
		color: white;
		/* hide a tag styling */
		text-decoration: none;
		text-align: center;
	}
	#MUI-description {
		margin-top: 10px;
		min-height: fit-content;
	}
	.card-title-div {
		font-size: 1rem !important;
		display: block !important;
		position: relative !important;
		margin-bottom: 40px !important;
	}

	.card-title {
		font-size: 2.5rem !important;
		text-decoration-thickness: 4px !important;
		text-align: center !important;
		/* padding-top: 30px !important; */
	}

	#card-media:hover {
		/* darken the card on hover */
		opacity: 1;
		/* darken the card on hover */
		cursor: url("../../../images/ring-pointer.png"), 4, 4 pointer !important;
	}

	.card-content-text {
		border: none !important;
	}

	#MUI-Card {
		border-radius: 10px;
		min-height: 450px;
	}

	.card-title-div {
		top: 0px !important;
		left: 0px !important;
	}
}

#MUI-Card:hover {
	transform: scale(1);
}

.MUI-icon {
	font-size: 3rem;
	margin-bottom: -25px;
}

@media (max-width: 455px) {
	#MUI-Card {
		width: 340px !important;
	}
}

@media (min-width: 1300px) {
	.darken-on-hover:hover {
		/* darken the card on hover */
		opacity: 0.3 !important;
		/* darken the card on hover */
	}
	#MUI-Card {
		margin-left: 0px !important;
		margin-right: 0px !important;
		width: 100% !important;
		font-size: 1.2rem;
		height: 100%;
		min-height: 525px;
	}

	#card-media {
		width: 100%;
		height: 525px;
		image-rendering: pixelated;
		cursor: url("../../../images/ring-pointer.png"), 4, 4 pointer !important;
	}

	.MuiCardHeader-title {
		font-size: 2rem !important;
	}

	.card-content {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: space-around;
	}

	.project-description {
		font-size: 1.25rem;
	}

	.icon-div {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
	}

	.card-content-text {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		max-width: 300px;
		height: 480px;
	}

	.MUI-icon:hover {
		transform: scale(1.5);
		transition: transform 0.5s ease-in-out;
		color: var(--secondary-color);
		transform: translateY(-5px);
	}

	#MUI-Card:hover {
		transform: scale(1);
	}

	@media (min-width: 1300px) {
		.MuiCardHeader-title {
			text-align: left;
		}

		#card-media {
			width: 1200px !important;
			max-width: 100% !important;
			cursor: url("../../../images/ring-pointer.png"), 4, 4 pointer !important;
		}
	}
}
