.Projects {
	text-align: center;
	width: 100%;
	user-select: none;
	margin-top: 150px;
	background: var(--black);
}

.projects-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.ReactModal__Overlay {
	background-color: var(--black) !important;
}

@media (max-width: 800px) {
	.project-icons {
		display: none !important;
	}
}

@media (min-width: 1300px) {
	.projects-container {
		width: 90%;
		margin: 60px auto;
		padding-bottom: 60px;
	}

	h1 {
		font-size: 2.5rem;
	}
}
