@import url("https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@700&display=swap");

* {
	cursor: url("./images/ring-pointer.png"), 4, 4 auto !important;
}
body {
	margin: 0;
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--black);
	cursor: url("./images/cursor-ring.png") 4 4, default !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	user-select: none;
	color: var(--off-white);
}

html {
	scroll-behavior: smooth !important;
}

a {
	cursor: url("./images/ring-pointer.png"), auto;
}

a :hover {
	cursor: url("./images/ring-pointer.png"), auto !important;
}

a::after {
	cursor: url("./images/ring-pointer.png"), 4, 4 pointer !important;
}

a:hover {
	cursor: url("./images/ring-pointer.png"), 4, 4 pointer !important;
}
:root {
	--primary-color: #303030;
	--secondary-color: #43c396;
	--secondary-color-dark: hsl(162, 55%, 29%);
	--off-white: #f5f5f5;
	--gray-background: rgb(35, 35, 35);
	--black: rgb(20, 22, 26);
}
