h1 {
	color: var(--off-white);
}

p {
	color: var(--off-white);
}

svg:hover {
	color: var(--secondary-color) !important;
}

.App {
	max-width: 100vw !important;
	overflow: hidden;
	background-color: var(--black);
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.ContributionCalendar-label {
	fill: var(--secondary-color) !important;
}

.contributions-header {
	margin-bottom: 20px !important;
}
