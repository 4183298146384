.social-links-vertical {
	position: fixed;
	left: 0px;
	bottom: 10px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	width: 75px;
	height: fit-content;
	border-radius: 20px;
	z-index: 0;
}

.social-links-vertical a {
	padding: 10px;
	color: var(--secondary-color);
}

.social-links-vertical .social-link-img:hover {
	color: var(--secondary-color) !important;
}

.social-links-vertical a:hover .social-link-text {
	color: "var(--secondary-color)" !important;
}

.social-link-text {
	display: none;
	position: absolute;
	left: 50px;
	bottom: 20px;
	width: 75px;
	height: 20px;
	background-color: var(--black);
	border-radius: 0px 0px 20px 20px;
	color: var(--secondary-color);
	font-size: 0.8rem;
	text-align: center;
	padding-top: 5px;
}

@media (max-width: 1000px) {
	.social-link-text {
		left: -10px;
		bottom: -25px;
	}
}

.social-link {
	font-size: 1.5rem;
	text-decoration: none !important;
	text-align: end;
	color: "var(--secondary-color)" !important;
	padding-bottom: 15px;
}

@media (max-width: 1200px) {
	.social-links-vertical {
		display: flex;
		position: relative;
		flex-direction: row;
		align-items: center;
		width: 80%;
		height: auto;
		left: 0%;
		margin-top: 20px;
	}
}

.social-link-img:hover {
	-webkit-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
}
